const contacts = {
  phones: [
    {
      href: "tel:+375447473737",
      value: "+375 44 747 3737"
    },
  ],
  address: {
    city: "Minsk",
    street: "Babushkina, 25A",
    longitude: '',
    latitude: ''
  },
  workHours: [
    "Mon-Sun: 9:00 - 18:00",
  ]
};

export default contacts;