import * as React from "react";
import {useInView} from 'react-intersection-observer';
import {trackEvent} from "../lib/track";

function Map() {
  const {ref, inView} = useInView({
    threshold: 0,
    triggerOnce: true
  });

  if (inView) {
    trackEvent("Map", "Show", "", true);
  }

  return (
    <div ref={ref} className="section-map" id="section-map">
      {
        inView &&
        <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=1030194488" width="100%" height="100%" frameborder="0" title="yandex map"></iframe>
      }
    </div>
  );
}

export default Map;