import * as React from "react";

function CleaningProcess() {
  return (
    <>
      <h3>First stage: preliminary inspection</h3>
      <p>
        Checking the integrity of the filter element: the presence of cracks, soldered or crumbled fragments. 
        If the filter is badly damaged, it cannot be cleaned, it must be replaced.
      </p>

      <h3>
        Second stage: installation of the filter in the device to evaluate the backpressure
      </h3>
      <p>
        The filter is fixed and hermetically sealed in the device. It passes the back pressure test and the air flow velocity through the filter element.
        Then the filter is filled with a special cleaning solution. 
        The cleaning agent helps to dissolve the dirt of the filter, and also protects its structure.
      </p>

      <h3>The third stage: washing</h3>
      <p>
      Liquid cleaning of the FAP filter is carried out in 4 stages:
      </p>

      <ul>
        <li>
          Cleaning in the direction of exhaust gases
        </li>
        <li>
          Cleaning against the direction of exhaust gases
        </li>
        <li>
          Repeated cleaning: the cleaning cycle in two directions is repeated once more
        </li>
      </ul>

      <p>
        At each stage of cleaning, the particles coming out of the filter are analyzed, so that the cause of contamination can be determined, as well as the duration of the process can be adjusted. 
        Thanks to the programs perfectly matched to each filter, the cleaning liquid is filled to the appropriate size, and a certain pressure of compressed air blows is selected, which completely protects the filter from mechanical damage.
      </p>

      <p>
        Cleaning of the filter is carried out in a specific sequence of filling and flow of a special solution, as well as blows of compressed air. The temperature of the solution is 70 degrees.
      </p>

      <h3>Fourth stage: drying</h3>
      <ul>
        <li>The filter is dried in two directions</li>
        <li>After complete drying, the filter is cooled</li>
        <li>Then the back pressure and flow velocity test is carried out again</li>
        <li>A report is printed in which you can trace the initial parameters of the filter and the final ones based on the results of cleaning
        </li>
      </ul>

      <p>
        <strong>
        The catalytic converters (CAT) can be cleaned at the installation, for which special cleaning settings are also carried out.
        </strong>
      </p>
    </>
  )
}

export default CleaningProcess;