import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Clock from "bootstrap-icons/icons/clock.svg";
import Truck from "bootstrap-icons/icons/truck.svg";
import Award from "bootstrap-icons/icons/award.svg";
import Droplet from "bootstrap-icons/icons/droplet.svg";
import { Messenger } from "../../Messenger";
import "./header.scss";

function IndexHeader() {
  return (
    <header id="section-header" className={"section-header"}>
      <Container>
        <Row>
          <h1>Cleaning of the particulate filter in Belarus <small>more profitable than replacing with a new one</small></h1>
          <p className="slogan">Conservation of the car factory settings and environmental standards of operation</p>
        </Row>

        <Row>
          <Col xl={8}>
            <ul className="advantages">
              <li>
                <i><Clock/></i>
                <div>
                  <span className="header">Within 15 minutes</span>
                  <span className="description">
                    Diagnostics of the removed particulate filter in the presence of the client<br/>
                    Cleaning process from 1 to 3 hours
                  </span>
                </div>
              </li>

              <li>
                <i><Truck/></i>
                <div>
                  <span className="header">Delivery within Belarus is free</span>
                  <span className="description">
                    We will pick up and deliver the particulate filter or catalyst
                  </span>
                </div>
              </li>

              <li>
                <i><Award/></i>
                <div>
                  <span className="header">Removal of 99% of contaminants</span>
                  <span className="description">
                    Arising from soot, sulfur, engine oil and coolant,<br/>
                    without damaging the filter cartridge
                  </span>
                </div>
              </li>

              <li>
                <i><Droplet/></i>
                <div>
                  <span className="header">DPF / FAP / SCR / KAT cleaning on modern European equipment</span>
                  <span className="description">
                    With the use of environmentally friendly detergents that are safe for the filter element.<br/>
                    Cleaning results are provided with indicators BEFORE and AFTER washing
                  </span>
                </div>
              </li>
            </ul>
          </Col>

          <Col xl={4}>
            <Messenger header="Contact us in messengers"/>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default IndexHeader;