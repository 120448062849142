import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {trackEvent} from "../lib/track";
import { Messenger } from "./Messenger";


function ModalMessenger({btnChild}) {
  const [show, setShow] = React.useState(false);
  const header = "Contact us in messengers";

  const showModal = () => {
    trackEvent("Modal", "Show", header, true);
    setShow(true);
  }

  const hideModal = () => {
    setShow(false);
  }

  return (
    <>
      <Button size="sm" onClick={showModal}>{btnChild}</Button>
      <Modal show={show} onHide={hideModal} className={"modal-messenger"}>
        <Modal.Header closeButton>{header}</Modal.Header>
        <Modal.Body>
          <Messenger />
        </Modal.Body>
      </Modal>
    </>
  )
}

ModalMessenger.propTypes = {
  btnChild: PropTypes.node.isRequired,
}

export default ModalMessenger;