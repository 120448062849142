import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Cert1Lg from "../images/cert-1-lg.jpg";
import Cert1Sm from "../images/cert-1-sm.jpg";
import Cert1SmWebp from "../images/cert-1-sm.jpg.webp";
import Cert2Lg from "../images/cert-2-lg.jpg";
import Cert2Sm from "../images/cert-2-sm.jpg";
import Cert2SmWebp from "../images/cert-2-sm.jpg.webp";
import Cert3Lg from "../images/cert-3-lg.jpg";
import Cert3Sm from "../images/cert-3-sm.jpg";
import Cert3SmWebp from "../images/cert-3-sm.jpg.webp";
import Cert4Lg from "../images/cert-4-lg.jpg";
import Cert4Sm from "../images/cert-4-sm.jpg";
import Cert4SmWebp from "../images/cert-4-sm.jpg.webp";
import Cert5Lg from "../images/cert-5-lg.jpg";
import Cert5Sm from "../images/cert-5-sm.jpg";
import Cert5SmWebp from "../images/cert-5-sm.jpg.webp";
import Cert6Lg from "../images/cert-6-lg.jpg";
import Cert6Sm from "../images/cert-6-sm.jpg";
import Cert6SmWebp from "../images/cert-6-sm.jpg.webp";
import "./about.scss";
import AboutCert from "./AboutCert";

function About() {
  return (
    <section className='section-about' id="section-about">
      <Container>
        <Row>
          <Col>
            <p>
              In Belarus our company is the official distributor of Total PDF, 
              a manufacturer of equipment for cleaning DPF/FAP/SCR/KAT particulate filters mounted in exhaust systems of all types of equipment.
            </p>

            <p>
              <strong>
                Thanks to many years of experience in the automotive industry, we provide high quality customer service
              </strong>
            </p>
          </Col>
        </Row>

        <div className={"certs"}>
          <AboutCert jpg={Cert1Sm} webp={Cert1SmWebp} alt={"сертификат"} href={Cert1Lg}/>
          <AboutCert jpg={Cert2Sm} webp={Cert2SmWebp} alt={"сертификат"} href={Cert2Lg}/>
          <AboutCert jpg={Cert3Sm} webp={Cert3SmWebp} alt={"сертификат"} href={Cert3Lg}/>
          <AboutCert jpg={Cert4Sm} webp={Cert4SmWebp} alt={"сертификат"} href={Cert4Lg}/>
          <AboutCert jpg={Cert5Sm} webp={Cert5SmWebp} alt={"сертификат"} href={Cert5Lg}/>
          <AboutCert jpg={Cert6Sm} webp={Cert6SmWebp} alt={"сертификат"} href={Cert6Lg}/>
        </div>
      </Container>
    </section>
  )
}

export default About;