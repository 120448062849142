import * as React from "react";
import RegenerationJpg from "../../../images/regeneration.jpg";
import RegenerationWebp from "../../../images/regeneration.jpg.webp";


function Regeneration() {
  return (
    <>
      <picture>
        <source type="image/webp" srcSet={RegenerationWebp}/>
        <img src={RegenerationJpg} alt="что такое регенерация" className="image-fluid"/>
      </picture>
      <h3>How does the filter work?</h3>
      <p>The DPF works by filtering and storing soot that occurs when the diesel engine is running.</p>

      <h3>What does the filter do when a lot of soot accumulates in it?</h3>
      <p>
        The DPF burns it at high temperatures, leaving only a little ash. This process is called regeneration.
      </p>
      <p>
        Regeneration burns off excess soot in the filter, reducing harmful exhaust emissions and helps prevent the black puffs of smoke that can sometimes be seen from old diesel cars when they accelerate.
      </p>
      <p>
        <strong>
        There are two types of regeneration – active and passive.
        </strong>
      </p>

      <h3>What is passive regeneration of a particulate filter?</h3>
      <p>
        Passive regeneration occurs while the vehicle is moving under the influence of exhaust gas temperature. 
        But not everything is so simple: the temperature needs to be quite high, and you will have to move for a long time.
      </p>
      <p>
        To be sure that passive regeneration occurs periodically, and it should occur 2-3 times during the engine oil change interval, 
        it is recommended to drive on the highway for at least 30-45 minutes at a speed of at least 80 km/h.
      </p>

      <h3>Let's consider the conditions of regeneration in a little more detail</h3>
      <p>
        When the filter is sufficiently dirty, the electronic control unit receives a signal and warns the car owner about the need for cleaning.
      </p>
      <p>
        The car can clean the DPF itself only under certain conditions:
      </p>
      <ul>
        <li>high exhaust gas temperature (from 500 deg. C)</li>
        <li>
          рengine operation with maximum load: 3000 rpm in third gear
        </li>
        <li>
          if the amount of fuel in the tank is less than a quarter, regeneration will not start. 
          It is desirable that the tank is half full, and the fuel level sensor is serviceable
        </li>
      </ul>

      <h3>What is active regeneration of the particulate filter?</h3>
      <p>
        Active regeneration works by injecting additional fuel to increase the exhaust gas temperature and burn soot in the particulate filter. 
        It is usually performed with the help of diagnostic equipment that starts the process.
      </p>
      <p>
        Depending on the vehicle and the filter design, the principle of starting active regeneration may be different. 
        Cleaning is controlled by an air flow meter, exhaust gas temperature sensors installed before and after the filter, as well as a differential pressure sensor in the particulate filter.
      </p>
      <p>
        Relying on sensor signals, the electronic control unit («brains») sends a signal for additional fuel injection into the combustion chamber, partially restricts the air supply and stops exhaust gas recirculation. 
        These conditions raise the temperature of the exhaust gases, which should help remove soot from the filter.
      </p>

      <h3>What prevents the filter from cleaning itself?</h3>
      <p>
        What can interfere with the process of passive regeneration? The simplest thing is driving in the city: short distances, low speed, traffic jams. 
        With such operation, the regeneration process will be interrupted, which will subsequently lead to a complete blockage of the filter element.
      </p>
      <p>
        Also, particulate filters are quite sensitive to the quality of engine oil and fuel.
      </p>
      <p>
        In case of unsuccessful regeneration attempts or sensor malfunctions, additional fuel for burning the filter can go into the oil. 
        This can be seen by the quality of the oil and its level, which is better to monitor periodically, especially if the car has already run more than 100 thousand km. 
        Incomplete regeneration will be resumed again and again until it is completed qualitatively.
      </p>

      <h3>What will happen to the car if you forget about the dirty particulate filter?</h3>
      <p>
        With the help of signals on the dashboard, the car warns the owner that the filter is clogged. 
        If regeneration does not occur for a long time, the system will put the car into emergency mode and limit some of its functions.
      </p>
      <p>
        So, a car with a clogged filter makes itself felt first of all by losing normal traction and dynamic characteristics («does not go»).
        The engine can run with noticeable interruptions. The emergency state of the electronic control system is activated. 
        The oil level in the engine will begin to increase, as a result, all this can lead to the fact that the engine «knocks».
      </p>
    </>
  )
}

export default Regeneration;