import * as React from "react";
import Container from "react-bootstrap/Container";
import "./ecology.scss";

function Ecology() {
  return (
    <section className="section-eco" id="section-eco">
      <Container>
        <div className="eco_card">
          <p>Equipment without harm to the environment!</p>
          <p>Detergents do not pollute the environment.</p>
          <p>Cleaning process takes place in a closed circuit of the machine, without releasing harmful substances into the atmosphere.</p>
        </div>
      </Container>
    </section>
  )
}

export default Ecology;